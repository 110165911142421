import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: 'api-doc', component: () => import('pages/ApiDocPage.vue') },
      { path: 'supervisor', component: () => import('pages/IndexPage.vue') },
      { path: '', redirect: 'supervisor' },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    redirect: ''
  },
];

export default routes;
