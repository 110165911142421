// src/boot/fontawesome.ts

import { boot } from 'quasar/wrappers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Add icons to the library
library.add(faSpinner);

export default boot(({ app }) => {
  // Register the FontAwesomeIcon component globally
  app.component('font-awesome-icon', FontAwesomeIcon);
});
